import React, { useEffect, useMemo, useRef, useState } from 'react'
import Plot from 'react-plotly.js'
import Box from '@mui/material/Box'
import { Button, Card, CardContent, CardHeader, Grid, IconButton, TextField, Tooltip, Checkbox, FormControlLabel } from '@mui/material'
import DOWNLOAD from '../../assets/svg/download-grey.svg'
import YAXISICON from '../../assets/svg/chart-y-axis.svg';
import vars from '../../styles/variables'
import AnalysisModal from './AnalysisModal'
import { converters } from '../../utils/plotting/plot.js'
import { getRawDetailedDataPlot, getRawDetailedDataPlotForDrug } from '../../utils/plotting/plot.js'

const { primaryFont } = vars

const chartStyles = {
  height: '20rem',
  width: '100%'
}

const chartConfig = {
  displaylogo: false,
  displayModeBar: false,
  responsive: true
}

const marginLayoutProps = {
  t: 0,
  l: 0,
  r: 0,
  b: 0
}

const hoverLayoutProps = {
  bgcolor: 'white',
  bordercolor: 'rgba(0, 0, 0, 0.16)',
  font: {
    size: 12,
    color: 'rgba(0, 0, 0, 0.6)'
  }
}

const legendProps = {
  orientation: 'h',
  itemsizing: 'constant',
  y: -0.50,
  x: -0.12
}

const horizontalLayout = {
  hovermode: 'x unified',

  font: {
    family: primaryFont
  },

  margin: {
    ...marginLayoutProps
  },

  barmode: 'stack',

  hoverlabel: {
    ...hoverLayoutProps
  },

  legend: {
    ...legendProps
  }
}

const classes = {
  card: {
    '& *': {
      fontFamily: `${primaryFont} !important`
    },
    '& .MuiCardHeader-root': {
      '& .MuiCardHeader-title': {
        fontSize: '1rem',
        lineHeight: '1.5rem'
      }
    },

    '& .MuiCardContent-root': {
      position: 'relative',

      '& .MuiButton-root': {
        position: 'absolute',
        right: '0.75rem',
        bottom: '0.75rem'
      }
    }
  }
}

export default function LineChart ({ header, drug, data, layout, rows, rejectedHeaders, downloadAsImage, applyToAll, handleApplyToAllToggle, meanStdevPerSampleTime, workbook, includeRejected, models }) {
  const originalData = getRawDetailedDataPlotForDrug(models, drug)
  const [open, setOpen] = useState(false);
  const [showField, setShowField] = useState(false);
  const chartId = `id-lc-${Math.random().toString(16).slice(2)}_chart`

  const [chartLayout, setChartLayout] = useState(layout)
  const converter = converters[chartLayout.yaxis.type]
  const [yMin, setYMin] = useState(layout?.yaxis?.range?.[0] ? converter.from(layout?.yaxis?.range?.[0]) : '')
  const [yMax, setYMax] = useState(layout?.yaxis?.range?.[1] ? converter.from(layout?.yaxis?.range?.[1]) : '')

  const updateYAxisMax = (e) => {
    const value = e.target.value
    const newLayout = {
      ...chartLayout,
      yaxis: {
        ...chartLayout.yaxis,
        autorange: false,
        range: [chartLayout?.yaxis?.range[0], converter.to(value)]
      }
    }
    setChartLayout(newLayout)
    setYMax(value)
    handleApplyToAllToggle(false, newLayout.yaxis.range);
  }

  const updateYAxisMin = (e) => {
    const value = e.target.value
    const newLayout = {
      ...chartLayout,
      yaxis: {
        ...chartLayout.yaxis,
        autorange: false,
        range: [converter.to(parseFloat(value)), chartLayout?.yaxis?.range[1]]
      }
    }
    setChartLayout(newLayout)
    setYMin(value)
    handleApplyToAllToggle(false, newLayout.yaxis.range);
  }

  let newLayout = { ...horizontalLayout, ...chartLayout }

  const handleCheckboxToggle = (e) => {
    const newYRange = [converter.to(yMin), converter.to(yMax)]
    // Call the callback function to toggle applyToAll in the parent component
    handleApplyToAllToggle(e.target.checked, newYRange)
  };

  useEffect(() => {
    if (!applyToAll) {
      return
    }
    setChartLayout(layout)
    setYMin(converter.from(layout.yaxis.range[0]))
    setYMax(converter.from(layout.yaxis.range[1]))
  }, [layout])

  return (
    <>
      <Grid item xs={12} md={6}>
        <Box sx={classes.card}>
          <Card variant='outlined'>
            <CardHeader
              action={
                <Box display="flex" alignItems="center">
                  <Box display="flex" alignItems="center" gap={1}>
                    {showField && (
                      <Box display="flex" width="15rem" alignItems="center" gap={1}>
                        <TextField placeholder='yMin' onChange={updateYAxisMin} value={isNaN(yMin) ? '' : yMin}/>
                        <TextField placeholder="yMax" onChange={updateYAxisMax} value={isNaN(yMax) ? '' : yMax}/>
                        <FormControlLabel sx={ { '& .MuiFormControlLabel-label': { whiteSpace: 'nowrap' } }} control={<Checkbox checked={applyToAll} onChange={handleCheckboxToggle} />} label="Apply to all" />
                      </Box>
                    )}

                    <Tooltip placement='top' title='Toggle yMin and yMax'>
                      <IconButton onClick={() => setShowField((prev) => !prev)}>
                        <YAXISICON style={{ height: '1.25rem', width: '1.25rem', fill: '#667085' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Tooltip placement='top' title='Download as image'>
                    <IconButton onClick={() => downloadAsImage(chartId, 'bottom')}>
                      <DOWNLOAD />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
              title={drug}
            />

            <CardContent>
              <Plot
                data={data}
                layout={ newLayout }
                config={chartConfig}
                style={chartStyles}
                divId={chartId}
              />
              <Button variant='outlined' onClick={() => setOpen(true)}>See more</Button>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      {(open && models) && <AnalysisModal open={open} handleClose={() => setOpen(false)} header={header}
        rows={rows} rejectedHeaders={rejectedHeaders} downloadAsImage={downloadAsImage}
        meanStdevPerSampleTime={meanStdevPerSampleTime}
        includeRejected={includeRejected}
        workbook={workbook} drug={drug} models={models}
        originalData={originalData}
      />}
    </>
  )
}
