import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './styles/style.less'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from './styles/theme'
import Dashboard from './components/Dashboard'
import initInstallListeners from './services/InstallService'
import SopLibrary from './components/SopLibrary'
import ComponentWrapper from './components/ComponentWrapper'
import { analyseModels } from './utils/javelinanalyser'
import { parseModelsFromXLSX } from './utils/javelinimporter'
import { ExperimentFileStatus } from './types/ExperimentFile'
import { updateFile, selectExperimentFiles } from './store/reducers/experimentFile'
import Analysis from './components/Analysis/Analysis'

const startAnalysis = (dispatch, experimentFiles) => {
  const filesToAnalyze = experimentFiles.filter((file) => file.analyzeStatus === ExperimentFileStatus.Pending)
  filesToAnalyze.forEach((file) => {
    console.log(`Start analyzing ${file.meta.name}`)
    dispatch(updateFile({ ...file, analyzeStatus: ExperimentFileStatus.Running }))
    parseModelsFromXLSX(file.content, file.meta.name, file.includeRejected)
      .then((models) => {
        dispatch(updateFile({
          ...file,
          data: models.importedJson,
          analyzeStatus: ExperimentFileStatus.XlsxImported
        }))
        analyseModels(models.importedJson)
          .then((analysisModel) => {
            dispatch(updateFile({
              ...file,
              data: models.importedJson, // necessary to add it, "non mutable" objects are considered by redux
              results: analysisModel,
              analyzeStatus: ExperimentFileStatus.Analyzed
            }))
          })
      })
  })
}

const Main = (props) => {
  const dispatch = useDispatch()
  const experimentFiles = useSelector(selectExperimentFiles)
  const [deferredPrompt, setDeferredPrompt] = useState(null)

  const installApp = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt()
      // Find out whether the user confirmed the installation or not
      // const { outcome } = await deferredPrompt.userChoice;
      await deferredPrompt.userChoice
      // The deferredPrompt can only be used once.
      setDeferredPrompt(null)
      // Act on the user's choice
      // if (outcome === 'accepted') {
      //   log('User accepted the install prompt.');
      // } else if (outcome === 'dismissed') {
      //   log('User dismissed the install prompt');
      // }
    }
  }

  useEffect(() => {
    initInstallListeners(setDeferredPrompt)
  }, [])

  useEffect(() => {
    if (experimentFiles.length > 0) {
      startAnalysis(dispatch, experimentFiles)
    }
  }, [dispatch, experimentFiles])

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ComponentWrapper handleClick={installApp} {...props}>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/soplibrary' element={<SopLibrary />} />
            <Route path='/analysis' element={<Analysis />} />
          </Routes>
        </ComponentWrapper>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default Main
