import { Box, FormControl, Select, OutlinedInput, Typography, Chip, MenuItem } from '@mui/material';
import React from 'react';
import { CloseIcon } from '../../../shared/Icons';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SelectInput({ inputLabel, placeholder, value, inputChange, chipIndex, defaultConditions, detailKey, handleDelete }) {
  return (
    <Box mb={2}>
      <Typography variant='body2'>{inputLabel}</Typography>
      <FormControl fullWidth>
        <Select
          multiple
          displayEmpty
          value={value}
          onChange={(e) => inputChange(e.target.value, detailKey, chipIndex)}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            {if (selected?.length === 0) {
              return <em>{placeholder}</em>
            }}

            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected?.map((value, index) => (
                  <Chip onDelete={() => handleDelete(index)} deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} />} key={value} label={value} />
                ))}
              </Box>
            )
          }}
          MenuProps={MenuProps}
        >
          {defaultConditions?.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
};
