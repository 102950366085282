import { Button, IconButton, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Right from "../../assets/svg/arrow-right.svg";
import Left from "../../assets/svg/arrow-left.svg";
import CheckIcon from '@mui/icons-material/Check';
import Delete from "../../assets/svg/delete.svg";
import Done from "../../assets/svg/check-circle.svg";
import ChevronRight from "../../assets/svg/chevron-right.svg";
import Button from '@mui/material/Button';
import vars from '../../styles/variables';
import { PlusIcon } from '../../shared/Icons';
import { CHIP_INPUTS } from '../../shared/constant';

const { inputBorderHoverColor, inputDisableBorder, white, tabTextColor, errorBg, successColor, primaryTextColor } = vars;

interface ITabWrapper {
  heading: string,
  children: any,
  value?: any,
  handleChange: any,
  arr?: any,
  tabValue?: any,
  handleTabChange?: any,
  onAdd?: any,
  onRemove?: any,
  cocktailValueChange?: any,
  cocktail?: any
  setElementFocus?: any
  onImportFromFileClick?: any
  onResetDefaults?: any
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabWrapper: React.FC<ITabWrapper> = ({ heading, children, value, handleChange, arr, tabValue,
                                             handleTabChange, onAdd, onRemove, cocktailValueChange, cocktail,
                                             setElementFocus, onImportFromFileClick, onResetDefaults }) => {
  const classes = {
    root: {
      display: 'flex',
      height: '100%'
    },

    sidebar: {
      width: '18.75rem',
      background: inputBorderHoverColor,
      borderRight: `0.0625rem solid ${inputDisableBorder}`,
      boxShadow: '0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)',

      '& h3': {
        borderBottom: `0.0625rem solid ${inputDisableBorder}`,
        padding: '1.5rem',
        margin: 0,
      }
    },

    cocktails: {
      height: value === 0 ? 'calc(100% - 17.3115rem)' : 'calc(100% - 10.3125rem)',
      overflow: 'auto',

      '& .MuiButton-text': {
        height: '4.5rem',
        borderBottom: `0.0625rem solid ${inputDisableBorder}`,
        color: tabTextColor
      },

      '& .MuiTabs-indicator': {
        width: '0.25rem',
        right: 'auto'
      },

      '& .MuiTab-root': {
        justifyContent: 'flex-start',
        height: '4.5rem',
        padding: '0 1.5rem',
        borderBottom: `0.0625rem solid ${inputDisableBorder}`,

        '&.Mui-selected': {
          background: white
        },

        '&:not(:first-of-type)': {
          marginLeft: 0,
        },
      }
    },

    content: {
      width: 'calc(100% - 18.75rem)',
      overflow: 'auto',
    },

    footer: {
      display: 'flex',
      '& .MuiButton-outlined': {
        padding: '0',
        marginRight: '0.5rem',
        flexShrink: 0,
        minWidth: '2.5rem'
      }
    },
  }
  const [editingItemId, setEditingItemId] = React.useState(null);

  const handleEditItemClick = (id) => {
    setEditingItemId(id);
  };

  const handleTextChange = (cocktailName, i) => {
    cocktailValueChange({
      ...cocktail,
      Name: cocktailName
    }, i)
  };

  const handleCheckIconClick = () => {
    setEditingItemId(null);
  };

  React.useEffect(() => {
    setElementFocus(true)
  }, [tabValue])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCheckIconClick()
    }
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.sidebar}>
        <Typography component="h3">{heading}</Typography>

        <Box sx={classes.cocktails}>
          { (value > 0 && value != 2) &&
            ( arr?.length > 0 && (
              <>
              <Tabs orientation="vertical" value={tabValue} onChange={handleTabChange}>
                {arr?.map((obj, i) => <Tab key={i} label={
                  <>
                    {value === 1 ? `${arr[i][CHIP_INPUTS.id] || 'New chip'}` : obj?.Name || `Solution ${i+1}`}
                    <Box display='flex' alignItems='center' ml='auto'>
                      {tabValue === i && arr.length > 1 &&
                        <IconButton onClick={() => onRemove(i)} sx={{
                          mr: 1
                        }}>
                          <Delete />
                        </IconButton>
                      }
                      <ChevronRight />
                    </Box>
                  </>
                } {...a11yProps(i)} />)}
              </Tabs>
              <Button fullWidth onClick={onAdd}>
                <PlusIcon className="left-icon" />
                Add a {value === 1 ? 'chip' : 'solution'}
              </Button>
            </>
          ) )
          }
          {(value === 2) &&
            (arr?.length > 0 && (
              <>
                <Tabs orientation="vertical" value={tabValue} onChange={handleTabChange}>
                  {arr?.map((obj, i) => <Tab key={i} label={
                    <>
                      {i === editingItemId ? <TextField autoFocus onChange={(e) => handleTextChange(e.target.value, i)} value={obj?.Name} onKeyPress={handleKeyPress}/> :  <span onClick={() => handleEditItemClick(i)}>{obj?.Name || `Solution ${i + 1}`}</span>}
                      <Box display='flex' alignItems='center' ml='auto'>
                        {i !== editingItemId ? (
                          <>
                          {tabValue === i && arr.length > 1 &&
                            <IconButton size='small' onClick={() => onRemove(i)} sx={{
                              mr: 0.5
                            }}>
                              <Delete />
                            </IconButton>
                          }
                          <ChevronRight />
                          </>
                        ) : (
                          <Box display="flex" ml={1.5} columnGap={0.5}>
                            <IconButton  color='success' size='small' onClick={handleCheckIconClick}>
                              <CheckIcon sx={{ fontSize: '1.25rem', color: successColor }} />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </>
                  } {...a11yProps(i)} />)}
                </Tabs>
                <Button fullWidth onClick={onAdd}>
                  <PlusIcon className="left-icon" />
                  Add a {value === 1 ? 'chip' : 'solution'}
                </Button>
              </>
            ))
          }
        </Box>

        <Box sx={{ ...classes.footer, flexDirection: value === 0 ? 'column' : 'row'}} p={3}>
          {value !== 0 &&
              <Button variant="outlined" onClick={(e) => handleChange(e, value - 1)}>
                <Left />
              </Button>
          }

          {value === 0 &&
              <>
                <Box mb={2}>
                <Button variant="outlined" component="label" fullWidth onClick={(e) => onResetDefaults(e)}>
                  Reset to defaults
                </Button>
              </Box><Box mb={2}>
                <Button variant="outlined" component="label" fullWidth onClick={(e) => onImportFromFileClick(e)}>
                  Import from file
                </Button>
              </Box>
              </>
          }

          <Button variant="contained" fullWidth onClick={(e) => handleChange(e, value + 1)}>
            {value === 4 ? (
                <>
                  <Done className="left-icon" />
                  {'Done'}
                </>
            ) : (
                <>
                  {'Next'}
                  <Right className="right-icon" />
                </>
            )}
          </Button>
        </Box>
      </Box>
      <Box sx={classes.content} p={3}>
        {children}
      </Box>
    </Box>
  )
};

export default TabWrapper