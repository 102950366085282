import { XlsxSheet, groupBy } from './xlsxwrapper.js'
import { utils, writeFileXLSX, writeXLSX } from 'xlsx'
import {
  Field,
  ExperimentEntry,
  ExperimentInfoEntry,
  ChipSpecificationEntry,
  DrugPropertyEntry
} from './xlsxmodel.js'

class JavelinXlsx {
  constructor (workbook, name) {
    this.wb = workbook
    this.name = name
    this._extractedSheets = null
    this.postValidation()
  }

  _findSheetsName (name) {
    const result = []
    for (const sheetName of this.wb.SheetNames) {
      if (sheetName.startsWith(name)) {
        result.push(sheetName)
      }
    }
    return result
  }

  writeFileXlsx (fileName) {
    return writeFileXLSX(this.wb, fileName)
  }

  writeXlsx () {
    return writeXLSX(this.wb, { type: 'base64', compression: true })
  }

  get dataSheets () {
    return { experiment: this.experiment, msdata: this.msdata }
  }

  get experiment () {
    return this.experiment_sheet
  }

  get msdata () {
    return this.msdata_sheet
  }

  get drugProperties () {
    return this.drugproperties_sheet
  }

  get clints () {
    return this.findSheets('clint')
  }

  get kps () {
    return this.findSheets('kp')
  }

  findSheets (name) {
    const sheets = []
    for (const sheetName of this._findSheetsName(name)) {
      sheets.push(new XlsxSheet(this.wb.Sheets[sheetName], sheetName, this.wb))
    }
    return sheets
  }

  postValidation () {
    const expSheetName = this._findSheetsName('experiment_info')
    const msdataSheetName = this._findSheetsName('MS_data')
    if (expSheetName.length === 0 || msdataSheetName.lenght === 0) {
      throw new Error(
        'Standardized format requires 2+ sheets: "experiment_info" and "MS_data"'
      )
    }
    if (expSheetName.length > 1) {
      throw new Error('There can be only 1 metadata worksheet')
    }
    this.experiment_sheet = new ExperimentSheet(
      this.wb.Sheets[expSheetName[0]],
      expSheetName[0],
      this.wb
    )
    this.msdata_sheet = new MSDataSheet(
      this.wb.Sheets[msdataSheetName[0]],
      msdataSheetName[0],
      this.wb
    )
    const drugPropertiesName = this._findSheetsName('drug_properties')
    if (drugPropertiesName.length > 0) {
      this.drugproperties_sheet = new DrugPropertiesSheet(
        this.wb.Sheets[drugPropertiesName[0]],
        drugPropertiesName[0],
        this.wb
      )
    } else {
      this.drugproperties_sheet = null
    }
  }

  extractSheets () {
    if (this._extractedSheets) {
      return this._extractedSheets
    }
    this._extractedSheets = {
      expinfo: {
        chips: this.experiment.extractEntries(),
        infos: this.experiment.experimentInfo
      },
      msdata: this.msdata.extractEntries(),
      drugproperties: this.drugProperties ? this.drugProperties.extractEntries() : null
    }
    return this._extractedSheets
  }

  aggregateAnalysisResults (results) {
    this.addSheetsFromObject(this._extractMpsAndKg(results))
  }

  addSheetsFromObject (obj) {
    for (const [key, rows] of Object.entries(obj)) {
      const sheet = utils.json_to_sheet(rows)
      let sheetName = key
      while (this.wb.SheetNames.some((x) => x === sheetName)) {
        sheetName = sheetName + '_new'
      }
      utils.book_append_sheet(this.wb, sheet, sheetName)
    }
  }

  _extractMpsAndKg (model) {
    const result = {
      clint: [],
      kp: []
    }
    for (const drugEntry of model) {
      const drugName = drugEntry.drug_name
      for (const chipResult of drugEntry.chips_analysis) {
        if (chipResult.clint) {
          const clint = {
            Row: `${drugName}-${chipResult.chip_id}`,
            CLintu_MPS: chipResult.clint.clintu_mps,
            CLintu_kg: chipResult.clint.clintu_h
          }
          result.clint.push(clint)
        }
        if (chipResult.kp) {
          const kp = {
            Row: `${drugName}-${chipResult.chip_id}`,
            Cmed: chipResult.kp.c_medium,
            Clys: chipResult.kp.c_lysate,
            'Clys/Cmed': chipResult.kp.clys_per_cmed
          }
          result.kp.push(kp)
        }
      }
    }
    return result
  }
}

class JavelineSheet extends XlsxSheet {
  constructor (sheet, name, workbook) {
    super(sheet, name, workbook)
    this.fields = {}
    const cells = this.find(Object.keys(this.metaFields))
    for (const [key, cell] of Object.entries(cells)) {
      cell.meta = this.metaFields[key]
      this.fields[key] = cell
    }
  }
}

const CHIP_DETAILS = 'Chip Details'
const EXPERIMENT_ID = 'Experiment ID'
const MPS_TYPE = 'MPS Type'
class ExperimentSheet extends JavelineSheet {
  get metaFields () {
    return {
      [CHIP_DETAILS]: new Field(this.CHIP_DETAILS),
      [EXPERIMENT_ID]: new Field('Experiment ID'),
      [MPS_TYPE]: new Field('MPS Type')
    }
  }

  constructor (...args) {
    super(...args)
    this.experimentInfo = new ExperimentInfoEntry(
      this.experimentId,
      this.mpsType
    )
    this.chips = this.extractChipDetails()
  }

  get experimentId () {
    return this.fields[EXPERIMENT_ID].right().v
  }

  get mpsType () {
    return this.fields[MPS_TYPE].right().v
  }

  extractEntries () {
    return this.extractChipDetails()
  }

  extractRawChipDetails () {
    const range = this._extractChipDetailsRange()
    return utils.sheet_to_json(this.sheet, {
      range,
      defval: null
    })
  }

  asJson () {
    return this.extractRawChipDetails()
  }

  asCSV () {
    const range = this._extractChipDetailsRange()
    return utils.sheet_to_csv(this.sheet, {
      range,
      defval: null,
      FS: ';'
    })
  }

  extractChipDetails () {
    return this.extractRawChipDetails().map(
      (x) => new ChipSpecificationEntry(x, this.sheet)
    )
  }

  _extractChipDetailsRange () {
    const startCell = this.fields[CHIP_DETAILS].right()
    const startCol = startCell.col
    const startRow = startCell.row
    const endRow = this._findLastChipDetailsRow()
    const endCol = this._findLastChipDetailsColumn()
    const range = {
      s: { r: startRow, c: startCol },
      e: { r: endRow, c: endCol }
    }
    return utils.encode_range(range)
  }

  _findLastChipDetailsRow () {
    let cell = this.fields[CHIP_DETAILS].right()

    while (cell.v && cell.v.trim() !== '') {
      cell = cell.down()
    }
    return cell.up().row
  }

  _findLastChipDetailsColumn () {
    const last = Object.keys(this.sheet).sort().reverse()[0]
    return utils.decode_cell(last).c
  }
}

class MSDataSheet extends JavelineSheet {
  get metaFields () {
    return {}
  }

  extractEntries () {
    return this.extractExperimentEntries()
  }

  extractExperimentEntries () {
    const rawObjs = utils.sheet_to_json(this.sheet, {
      defval: null
    })
    return rawObjs.map(x => new ExperimentEntry(x, this.sheet))
  }

  extractDisplayedRows () {
    // TODO rewrite my logic at some point
    const drugrows = this.asArray()
    const drugRowIdx = drugrows[0].findIndex(x => x.toLowerCase().trim() === 'drug') // We look for the drug column index
    const filteredDrugRows = groupBy(drugrows.slice(1), row => row[drugRowIdx].toLowerCase()) // group by drug
    const rejectedHeaders = []
    rejectedHeaders.push(5, 6, 7, 13, 14) // we reject by default some headers
    if (drugRowIdx < 2) { // If there is no "Sample ID header"
      rejectedHeaders.push(12)
    }
    for (const rows of filteredDrugRows.values()) {
      rows.splice(0, 0, drugrows[0]) // add header to each group
    }
    return [filteredDrugRows, rejectedHeaders]
  }
}

class DrugPropertiesSheet extends JavelineSheet {
  get metaFields () {
    return {}
  }

  extractEntries () {
    return this.extractDrugPropertiesEntries()
  }

  extractDrugPropertiesEntries () {
    const rawObjs = utils.sheet_to_json(this.sheet, {
      defval: null
    })
    return rawObjs.map((x) => new DrugPropertyEntry(x, this.sheet))
  }
}

export { JavelinXlsx }
