import { Box, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import React from 'react';
import vars from '../../../styles/variables';
import CLOSE from "../../../assets/svg/close.svg";
import {
  MuiChipsInput,
} from 'mui-chips-input'
import SelectInput from './SelectInput';
import { CHIP_INPUTS, COCKTAIL_INPUTS, LOCATION_INPUTS } from '../../../shared/constant';
import ChipInput from './ChipInput';

const { inputDisableBorder, labelColor } = vars;

const KEYBOARD_KEY = {
  comma: ',',
  space: ' ',
  enter: 'Enter'
};

const ACCEPTED_KEYS = [
  KEYBOARD_KEY.enter,
  KEYBOARD_KEY.space,
  KEYBOARD_KEY.comma
];

const defaultLocations = [
  LOCATION_INPUTS.locationNA,
  LOCATION_INPUTS.locationReoxygenationChamber,
  LOCATION_INPUTS.locationCellCamber
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-experiemntdetail-${index}`}
      aria-labelledby={`experimentdetail-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

export default function ExperimentDetail(props) {
  const { showDrugs, cocktail, cocktailValueChange, detailKey, linkedChips, heading, tabValue, chips } = props
  const classes = {
    root: {
      borderLeft: `0.25rem solid ${inputDisableBorder}`,
      paddingLeft: '1.5rem',
    },
    muiChipInput: {
      '& .MuiSvgIcon-root': {
        background: `url(${CLOSE})`,
        '& path': {
          display: 'none',
        }
      }
    },
    label: {
      color: labelColor
    },
  };

  const handleChange = (value, key) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [key]: value
      }
    })
  }

  const handleChangeDrugs = (value  ) => {
    cocktailValueChange({
      ...cocktail,
      [COCKTAIL_INPUTS.drugs]: value
    })
  }

  const handleChangeIntracellular = (value, key) => {
    const newIntracellular = {...cocktail[detailKey][COCKTAIL_INPUTS.intracellular], [key]: value}
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [COCKTAIL_INPUTS.intracellular]: newIntracellular
      }
    })
  }

  const handleDeleteIntracellular = (chipIndex, cocktail, detailKey, inputKey) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [COCKTAIL_INPUTS.intracellular]: { ...cocktail[detailKey][COCKTAIL_INPUTS.intracellular], [COCKTAIL_INPUTS[inputKey]] : cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS[inputKey]].filter((_, index) => index !== chipIndex) }
      }
    })
  }

  const handleDelete = (chipIndex, cocktail, detailKey, inputKey) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [inputKey]: [...cocktail[detailKey][inputKey].filter((_, index) => index !== chipIndex)]
      }
    })
  }

  return (
    <>
      <TabPanel value={tabValue} index={tabValue} key={tabValue}>
        { showDrugs &&
          <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
            <Typography sx={classes.label} variant='body2'>Drug(s)</Typography>
            <MuiChipsInput
              addOnWhichKey={ACCEPTED_KEYS}
              value={cocktail[COCKTAIL_INPUTS.drugs]}
              onChange={(val) => handleChangeDrugs(val)}
              fullWidth
              hideClearAll
              placeholder='Enter 1 or more'
            />
          </Box>
        }
        <Box mb={2} sx={classes.muiChipInput} flexGrow={1}>
          <Typography sx={classes.label} variant='body2'>{ showDrugs ? 'Dosing Times (hr)' : 'Sample Collection Times (hr)'  }</Typography>
          <MuiChipsInput
            addOnWhichKey={ACCEPTED_KEYS}
            value={cocktail[detailKey][COCKTAIL_INPUTS.timepoints]}
            onChange={(val) => handleChange(val, COCKTAIL_INPUTS.timepoints )}
            fullWidth
            hideClearAll
          />
        </Box>
        <ChipInput mb={2} key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.sampleVolume}`} val={cocktail[detailKey][COCKTAIL_INPUTS.sampleVolume]} index={tabValue} onChange={handleChange} detailKey={COCKTAIL_INPUTS.sampleVolume} />
        <SelectInput
          inputLabel={COCKTAIL_INPUTS.locations}
          placeholder="Select one or multiple sample locations"
          inputChange={handleChange}
          value={cocktail[detailKey][COCKTAIL_INPUTS.locations]}
          chipIndex={tabValue}
          defaultConditions={defaultLocations}
          detailKey={COCKTAIL_INPUTS.locations}
          handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.locations)}/>
        {linkedChips &&
          <SelectInput
            inputLabel={COCKTAIL_INPUTS.linkedChips}
            placeholder="Select one or multiple linked chips"
            inputChange={handleChange}
            value={cocktail[detailKey][COCKTAIL_INPUTS.linkedChips]}
            chipIndex={tabValue}
            defaultConditions={chips.map((c) => c[CHIP_INPUTS.id])}
            detailKey={COCKTAIL_INPUTS.linkedChips}
            handleDelete={(chipIndex) => handleDelete(chipIndex, cocktail, detailKey, COCKTAIL_INPUTS.linkedChips)}
          />
        }
        <ChipInput mb={2} key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.technicalReplicates}`} val={cocktail[detailKey][COCKTAIL_INPUTS.technicalReplicates]} index={tabValue} onChange={handleChange} detailKey={COCKTAIL_INPUTS.technicalReplicates} />

        { cocktail[detailKey][COCKTAIL_INPUTS.intracellular] && (
          <>
            <FormGroup sx={{
              my: 4
            }}>
              <FormControlLabel
                control={<Switch />}
                label='Add Intracellular Condition properties'
                onChange={(e) => handleChangeIntracellular(e?.target?.checked, 'selected')}
                checked={cocktail[detailKey][COCKTAIL_INPUTS.intracellular]?.selected} />
            </FormGroup>
            { cocktail[detailKey][COCKTAIL_INPUTS.intracellular].selected && (
              <>
                <ChipInput
                  mb={2}
                  key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.sampleVolume}_0`}
                  val={cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS.sampleVolume]}
                  index={tabValue}
                  onChange={handleChangeIntracellular}
                  detailKey={COCKTAIL_INPUTS.sampleVolume} />
                <SelectInput
                  inputLabel={COCKTAIL_INPUTS.locations}
                  placeholder="Select one or multiple sample locations"
                  inputChange={handleChangeIntracellular}
                  value={cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS.locations]}
                  chipIndex={tabValue}
                  defaultConditions={defaultLocations}
                  detailKey={COCKTAIL_INPUTS.locations}
                  handleDelete={(chipIndex) => handleDeleteIntracellular(chipIndex, cocktail, detailKey, 'locations')}
                />
                <ChipInput
                  mb={2}
                  key={`${heading}_${tabValue}_${COCKTAIL_INPUTS.technicalReplicates}_1`}
                  val={cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS.technicalReplicates]}
                  index={tabValue}
                  onChange={handleChangeIntracellular}
                  detailKey={COCKTAIL_INPUTS.technicalReplicates} />
              </>
            )}
          </>
        )}
      </TabPanel>
    </>
  )
}
