import * as React from 'react'
import { Button, Typography, Box, IconButton, FormControlLabel, Switch, FormControl, RadioGroup, Radio, FormLabel } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import vars from '../../../styles/variables'
import EXPERIMENT from '../../../assets/img/exp.jpg'
import DOWNLOAD from '../../../assets/svg/download.svg'
import TICK from '../../../assets/svg/tick.svg'
import { generateXlsx } from '../../../utils/xlsx/xlsxgenerator.js'
import { utils, writeFile } from 'xlsx'
import { EXPERIMENT_INPUTS } from '../../../shared/constant'
const { dialogTitleColor, secondaryFont } = vars

// const SHEETNAME_LIMIT = 30

// const truncate = (s, limit) => {
//   return s.length > limit ? s.substring(0, limit) : s
// }

export default function CompletionDialog (props) {
  const [timePointOrder, setTimePointOrder] = React.useState("ascendant")

  const classes = {
    root: {
      '& .MuiTypography-root': {
        marginBottom: '1rem',
        maxWidth: '26.25rem',
        textAlign: 'center'
      },

      '& .MuiTypography-body1:not(.MuiFormControlLabel-label)': {
        padding: 0,
        fontWeight: 500,
        fontSize: '1.5rem',
        maxWidth: '100%',
        marginTop: '1rem',
        lineHeight: '1',
        fontFamily: secondaryFont,
        color: dialogTitleColor
      },

      '& .MuiIconButton-root': {
        marginLeft: 'auto',
        marginTop: '0.5625rem',
        marginRight: '0.5625rem'
      },

      '& .MuiFormControlLabel-label': {
        marginTop: 0,
        marginBottom: 0
      },

      '& div > img:last-of-type': {
        marginTop: '1.5rem',
        maxWidth: '100%',
        maxHeight: '18.25rem'
      }
    }
  }

  const saveAsExcel = () => {
    const experimentContent = props?.experiment
    const experimentID = experimentContent[EXPERIMENT_INPUTS.id]
    const { chipDataSheet, msDataSheet, drugPropertiesSheet } = generateXlsx(experimentContent, timePointOrder === "descendant")

    const wb = utils.book_new()
    const expInfoSheetName = 'experiment_info'
    const msdataSheetName = 'MS_data'
    const drugpropertiesSheetName = 'drug_properties'
    utils.book_append_sheet(wb, chipDataSheet, expInfoSheetName)
    utils.book_append_sheet(wb, msDataSheet, msdataSheetName)
    utils.book_append_sheet(wb, drugPropertiesSheet, drugpropertiesSheetName)
    writeFile(wb, `Standardized LiverChip data file_${experimentID}.xlsx`)
    props?.handleClose()
  }

  return (
    <Box sx={classes.root} maxHeight={627}>
      <Box width={1} alignItems='center' display='flex' flexDirection='column' justifyContent='flex-end'>
        <IconButton
          aria-label='close'
          onClick={props?.handleClose}
        >
          <CloseIcon />
        </IconButton>
        <TICK />
        <Typography>Your template file is ready for download.</Typography>
        <Typography variant='body2'>
          You can now download the template data file and fill it with your experiments data.
        </Typography>

        <FormLabel sx={{textAlign: 'center'}}>Sample Time Sorting Order</FormLabel>
        <FormControl sx={{ mb: 1}}>
          <RadioGroup
            row
            aria-labelledby="timepoint-radio-buttons-group-label"
            name="timepoint-radio-buttons-group"
            value={timePointOrder}
            onChange={(e) => setTimePointOrder(e.target.value)}
          >
            <FormControlLabel value="ascendant" control={<Radio />} label="Ascending Order" />
            <FormControlLabel value="descendant" control={<Radio />} label="Descending Order" />
          </RadioGroup>
        </FormControl>

        <Button sx={{ flexShrink: 0 }} variant='contained' onClick={saveAsExcel}>
          <DOWNLOAD className='left-icon' />
          Download template file
        </Button>
        <img src={EXPERIMENT} />
      </Box>
    </Box>
  )
}
