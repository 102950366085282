import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import registerServiceWorker from './serviceWorkerRegistration'

import * as appVersionJson from './version.json'

import App from './App'
import store from './store/store'

export const appVersion = appVersionJson
const root = ReactDOM.createRoot(document.getElementById('root'))
const version = appVersion.version
const build = appVersion.build
root.render(
  <Provider store={store}>
    <App version={version} build={build} />
  </Provider>
)

registerServiceWorker()
