import React from 'react';
import Input from './Input';

interface IInput {
  val: any,
  onChange: any,
  index: number,
  detailKey: string,
  mb?: number,
}

const ChipInput: React.FC<IInput> = ({ val, onChange, index, detailKey, mb }) => {
  return (
    <Input mb={mb} label={detailKey} value={val} onChange={(val) => onChange(val, detailKey, index)} />
  )
};

export default ChipInput;