export const CHIP_INPUTS = {
  id: 'Chip ID',
  flowRate: 'Flow Rate',
  totalVol: 'Total Volume',
  hepNum: 'Hepatocyte Number',
  hepDonor: 'Hepatocyte Donor',
  lyVol: 'Lysis Volume',
  lungVol: 'Final Lung Volume',
  rna: 'RNA',
  dna: 'DNA',
  mediaType: 'Media Type',
  cocktails: 'Cocktails',
  timepoints: 'Timepoints',
  locations: 'Locations',
  conditions: 'Conditions',
  metVol: 'Metabolism Volume',
  nsbVol: 'NSB Volume',
  wsVol: 'Working Solution Volume',
  intVol: 'Intracellular Volume',
  comment: 'Comment'
}

export const COCKTAIL_INPUTS = {
  cocktails: 'Cocktails',
  drugs: 'Drugs',
  timepoints: 'Timepoints',
  sampleVolume: 'Sample Volume',
  locations: 'Locations',
  technicalReplicates: 'Technical Replicates',
  linkedChips: 'Linked Chips (biological replicates)',
  intracellular: 'Intracellular',
  workingSolution: 'workingSolution',
  metabolismCondition: 'metabolismCondition',
  nsbCondition: 'nsbCondition'
}

export const EXPERIMENT_INPUTS = {
  id: 'Experiment ID',
  chips: 'chips',
  cocktails: 'cocktails'
}

export const LOCATION_INPUTS = {
  locationNA: 'N/A',
  locationReoxygenationChamber: 'Reoxygenation Chamber',
  locationCellCamber: 'Cell Chamber'
}

export const chipKeys = [
  CHIP_INPUTS.flowRate,
  CHIP_INPUTS.totalVol,
  CHIP_INPUTS.hepNum,
  CHIP_INPUTS.hepDonor,
  CHIP_INPUTS.lyVol,
  // CHIP_INPUTS.lungVol,
  // CHIP_INPUTS.rna,
  // CHIP_INPUTS.dna,
  CHIP_INPUTS.mediaType
];

export const CHIP_DEFAULT_FLOW_RATE = '2 mL/h'
export const CHIP_DEFAULT_TOTAL_VOL = '1.9 mL'
export const CHIP_DEFAULT_HEP_NUM = '215000'
export const CHIP_DEFAULT_HEP_DONOR = 'WDH'
export const CHIP_DEFAULT_LY_VOL = '0.2 mL'
export  const CHIP_DEFAULT_MEDIA_TYPE = 'HMM'

export const WORKING_SOLUTION = 'Working Solution'
export const INTRACELLULAR_CONDITION = "Intracellular"
export const METABOLISM_CONDITION = "Metabolism"
export const NSB_CONDITION = "NSB"

export const LOCAL_STORAGE_EXPERIMENT = 'experiment'