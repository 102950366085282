import { utils } from 'xlsx'

class Field {
  constructor (name, hasUnit = false) {
    this.name = name
    this.hasUnit = hasUnit
  }

  get fieldName () {
    return this.name.toLowerCase().replace(/[ ,]/g, '_')
  }

  set (instance, instanceValue) {
    Object.defineProperty(instance, this.fieldName, {
      enumerable: true,
      get: function () {
        return instanceValue
      },
      set: function (value) {
        instanceValue.value = value
        const position = utils.encode_cell({ c: instanceValue._meta.col, r: this.rowNumber })
        const cell = this.__xlsx__[position]
        if (!cell) {
          utils.sheet_add_aoa(this.__xlsx__, [[value]], { origin: position })
          return
        }
        cell.v = value
      }
    })
  }
}

class FieldInstance {
  constructor (name, unit, field, col) {
    this.name = name
    this.raw_unit = unit
    this.field = field
    this.col = col
  }
}

class InstanceValue {
  constructor (_meta, value) {
    this.value = value
    this._meta = _meta
  }

  get unit () {
    return this._meta.raw_unit || ''
  }
}

class XlsxEntry {
  constructor (rawObj, sheet) {
    this.automap(rawObj)
    this.rowNumber = rawObj.__rowNum__
    this.__xlsx__ = sheet
  }

  automap (raw) {
    for (const [i, [key, value]] of Object.entries(raw).entries()) {
      const title = /([^(]*)(\((.*)\))?.*/.exec(key)
      let field
      if (title[2]) {
        field = new Field(title[1].trim(), true)
        field.set(
          this,
          new InstanceValue(new FieldInstance(key, title[3], field, i), value)
        )
      } else {
        field = new Field(title[0].trim(), false)
        field.set(
          this,
          new InstanceValue(new FieldInstance(key, null, field, i), value)
        )
      }
    }
  }
}

class ChipSpecificationEntry extends XlsxEntry {

}

class ExperimentEntry extends XlsxEntry {

}

class DrugPropertyEntry extends XlsxEntry {

}

class ExperimentInfoEntry {
  constructor (experimentId, mpsType) {
    this.experiment_id = experimentId
    this.mps_type = mpsType
  }
}

export {
  Field,
  ChipSpecificationEntry,
  ExperimentEntry,
  ExperimentInfoEntry,
  DrugPropertyEntry
}
