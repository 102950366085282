import { utils } from 'xlsx'
import { INTRACELLULAR_CONDITION, METABOLISM_CONDITION, NSB_CONDITION, WORKING_SOLUTION } from '../../shared/constant'

const hiddenCols = {
  experimentSheet: ['H', 'I', 'J', 'L']
}

const condMapping = {
  metabolismCondition: {
    name: METABOLISM_CONDITION
  },
  nsbCondition: {
    name: NSB_CONDITION
  }
}

const range = function (size, startAt = 0) {
  return [...Array(parseInt(size)).keys()].map((i) => i + startAt)
}

// Sameless copied from stackoverflow
const cartesian = function (...args) {
  const r = []
  const max = args.length - 1
  const helper = function (arr, i) {
    for (let j = 0, l = args[i].length; j < l; j++) {
      const a = arr.slice(0) // clone array
      a.push(args[i][j])
      if (i === max) {
        r.push(a)
      } else {
        helper(a, i + 1)
      }
    }
  }
  helper([], 0)
  return r
}

// Resolve/link all element as if we would have a real/strong
// model with bidirectionnal links
const extractCocktailsChip = function (content) {
  const cocktails = [...content.cocktails]
  for (const cocktail of cocktails) {
    const metabolism = cocktail.metabolismCondition
    metabolism.chips = content.chips.filter(x => metabolism['Linked Chips (biological replicates)'].includes(x['Chip ID']))
    cocktail.chips = [...metabolism.chips] // link to all used chips for this cocktail
    const nsb = cocktail.nsbCondition
    if (nsb && nsb['Linked Chips (biological replicates)'].length > 0) {
      nsb.chips = content.chips.filter(x => nsb['Linked Chips (biological replicates)'].includes(x['Chip ID']))
      cocktail.chips.push(...nsb.chips.filter(x => !cocktail.chips.includes(x['Chip ID'])))
    }
    content.chips.filter(x => cocktail.chips.map(c => c['Chip ID']).includes(x['Chip ID'])).forEach(x => {
      if (!('cocktails' in x)) {
        x.cocktails = []
      }
      if (!x.cocktails.filter(x => x.name).includes(cocktail.name)) {
        x.cocktails.push(cocktail)
      }
    })
  }
  return cocktails
}

// Generate all the combinations for the MSData
// for all chips/cocktail/drugs/...
// FIXME, the worksolIdx works, but it's ugly
const generateMSData = function (cocktails, descending) {
  let worksolIdx = 1
  const sheetContent = []
  const sortFun = descending ? (a, b) => b - a : (a, b) => a - b // we select the right sorting function
  const allDrugs = cocktails.flatMap(cocktail => cocktail.Drugs)

  for (const [i, cocktail] of cocktails.entries()) {
    const { Drugs: drugs, workingSolution } = cocktail
    const cocktailIndex = i + 1

    // cartesian(drugs, condChips, techReplicates, times)

    for (const [conditionTechName, { name: conditionName }] of Object.entries(condMapping)) {
      const condition = cocktail[conditionTechName]
      if (!condition) {
        continue
      }
      const condChips = condition.chips
      if (!condChips || condChips.length === 0) {
        continue
      }
      // Metabolism & NSB
      for (const drugName of drugs) {
        // const globalDrgIdx = cocktailIndex * (drgIdx + 1)
        const globalDrgIdx = allDrugs.indexOf(drugName) + 1
        const metaNsbIndex = sheetContent.length // We save at which index starts the drug (used later for inserting the WS entries depending on the sorting order)
        const techReplicates = range(condition['Technical Replicates'], 1)
        const times = condition.Timepoints.map(x => parseInt(x)).sort(sortFun)
        // We generate the combinations for drugs x techReplicates x times
        // (cartesian product of techReplicates by times)
        // Metabolism/NSB data
        let obj
        const conditionCombination = cartesian(techReplicates, times)
        for (const [chipIdx, chip] of condChips.entries()) {
          const condStartIndex = sheetContent.length
          for (const [timeIdx, [techrep, time]] of conditionCombination.entries()) {
            const bioreplicate = condChips.findIndex(c => c === chip) + 1
            const sortedTimeIdx = descending ? conditionCombination.length - timeIdx : timeIdx + 1
            const suffix = conditionName === 'NSB' ? 'N' : ''
            obj = {
              'Sample ID': `C${chipIdx + 1}D${globalDrgIdx}T${sortedTimeIdx}R${techrep}${suffix}`,
              'Chip ID': chip['Chip ID'],
              Drug: drugName,
              'Sample time (h)': time,
              Condition: conditionName,
              'Technical Replicate': techrep,
              'Biological Replicate': bioreplicate,
              'Working Solution': worksolIdx,
              'Sample Volume (uL)': parseInt(condition['Sample Volume'].replace(/[^.\d]+/, '')),
              'Sample Location': condition.Locations[0], // if we have a single element, adapt me
              Solution: cocktailIndex,
              // 'Concentration (nM)': '',
              Data: '',
              'Reject (mark "x")': '',
              Comments: ''
            }
            sheetContent.push(obj)
          }
          // We generate the intracellular entries if necessary
          if (INTRACELLULAR_CONDITION in condition && condition.Intracellular.selected) {
            const lysateTime = descending ? 0 : times.length - 1 // Depending on the sorting order, we either select the first or last element
            const lysateEntries = []
            for (const techrep of range(condition.Intracellular['Technical Replicates'], 1)) {
              const o = descending ? { ...sheetContent[condStartIndex] } : { ...obj }
              o['Sample ID'] += 'L'
              o.Condition = INTRACELLULAR_CONDITION
              o['Sample time (h)'] = times[lysateTime]
              o['Technical Replicate'] = techrep
              o['Sample Location'] = condition.Intracellular.Locations[0] // if we have a single element as location instead of a collection, adapt me
              o['Sample Volume (uL)'] = parseInt(condition.Intracellular['Sample Volume'].replace(/[^.\d]+/, ''))
              lysateEntries.push(o)
            }
            if (descending) {
              // if earliest first order, we insert the lysate entry *before* the condition entries
              sheetContent.splice(condStartIndex, 0, ...lysateEntries)
            } else {
              // if not, we insert it *after* the condition entries
              sheetContent.push(...lysateEntries)
            }
          }
        }
        // Generate working solutions (WS) entry only once by cocktail
        const wsTimes = workingSolution.Timepoints.map(x => parseInt(x)).sort(sortFun)
        const wsTechreps = range(workingSolution['Technical Replicates'], 1)
        // We generate the combinations for drugs x techReplicates x times
        // (cartesian product of techReplicates by times)
        const workingSolutionsCombinations = cartesian(wsTechreps, wsTimes)
        const wsEntries = []
        for (const [techrep, time] of workingSolutionsCombinations) {
          wsEntries.push({
            'Sample ID': `C0D${globalDrgIdx}T0R${techrep}`,
            'Chip ID': 'Tube',
            Drug: drugName,
            'Sample time (h)': time,
            Condition: WORKING_SOLUTION,
            'Technical Replicate': techrep,
            'Biological Replicate': 0,
            'Working Solution': worksolIdx,
            'Sample Volume (uL)': parseInt(workingSolution['Sample Volume'].replace(/[^.\d]+/, '')),
            'Sample Location': workingSolution.Locations[0],
            Solution: cocktailIndex,
            // 'Concentration (nM)': '',
            Data: '',
            'Reject (mark "x")': '',
            Comments: ''
          })
        }
        if (descending) {
          // if earliert first, we insert the WS *after* the condition/lysate entries
          sheetContent.push(...wsEntries)
        } else {
          // if not, we insert them *before* the condition/lysate entries
          sheetContent.splice(metaNsbIndex, 0, ...wsEntries)
        }
      }
      // The worksolIdx depends on the condition, more or less, it's increased
      // only in the case of "Metabolism"
      // the thing being that for NSB, there is usually two cocktails counted
      // as one big cocktails, but in the entries, the references to each
      // cocktail must be present
      // for example, C1 & C2 is considered as one cocktail #3, but each entries
      // in the xlsx must reference 1 or 2 depending on the drug while the working solution
      // of the same row must reference 3.
      // I'm ugly, please, think me better and change me :'(
      if (conditionName === METABOLISM_CONDITION) {
        worksolIdx++
      }
    }
  }
  return sheetContent
}

// Generates the chip data, linking the cocktails
const generateChipData = function (content) {
  const cocktails = extractCocktailsChip(content)
  const startRow = Object.keys(cocktails).length + 1
  const ref = {
    c: 1,
    r: Math.max(3, startRow) // There is at least the two headers for exp/chip model
  }
  const rows = []
  for (const chip of content.chips) {
    // Computes the used cocktails regarding to the name as they will be written in the xlsx
    let usedCocktails = 'Missing solution'
    if (chip.cocktails) {
      usedCocktails = chip.cocktails.map((x, i) => `${x.Name || `Solution ${i + 1}`}`)
        .sort()
        .join(' & ')
    }

    // Preset some data, chips that includes NSB condition
    // don't have hepdonor, hepnumber or lysvol (see ref xlsx)
    let hepdonor, hepnumber, lysvol
    if (Number.isNaN(parseFloat(chip['Lysis Volume'].replace(/[^.\d]+/, '')))) {
      hepdonor = '-'
      hepnumber = 0
      lysvol = '-'
    } else {
      hepdonor = chip['Hepatocyte Donor']
      hepnumber = parseInt(chip['Hepatocyte Number'])
      lysvol = parseFloat(chip['Lysis Volume'].replace(/[^.\d]+/, ''))
    }
    // Create/push the data
    // the order of the key is the order in which they will appear in the XLXS
    rows.push({
      'Chip ID': chip['Chip ID'],
      'Flow Rate (mL/h)': parseFloat(chip['Flow Rate'].replace(/[^.\d]+/, '')),
      'Total Volume (mL)': parseFloat(chip['Total Volume'].replace(/[^.\d]+/, '')),
      'Hepatocyte Number': hepnumber,
      'Hepatocyte Donor': hepdonor,
      'Lysis Volume (mL)': lysvol,
      'Final Lung Volume (mL)': '',
      'RNA (ng)': '',
      'DNA (ng)': '',
      'Media Type': chip['Media Type'],
      'Study Duration (days)': '',
      Comments: usedCocktails
    })
  }
  return { rows, cocktails, ref }
}

const generateMSDataSheet = function (cocktails, descending) {
  const jsondata = generateMSData(cocktails, descending)
  return utils.json_to_sheet(jsondata)
}

const generateChipDataSheet = function (content) {
  // We generate the data with the reference point to where
  // start to store the data in the sheet
  const { rows, cocktails, ref } = generateChipData(content)

  // We generate an empty sheet
  const ws = utils.json_to_sheet([])
  utils.sheet_add_json(ws, rows, { origin: ref }) // We add to origin

  // We manually add the last missing information
  // rows for the experiment id
  ws.A1 = {
    t: 's',
    v: 'Experiment ID'
  }
  ws.B1 = {
    t: 's',
    v: content['Experiment ID']
  }

  // Rows for the mps type
  ws.A2 = {
    t: 's',
    v: 'MPS Type'
  }
  ws.B2 = {
    t: 's',
    v: 'Javelin-Liver-Chip'
  }

  // Cell for Chips Details
  ws[
    utils.encode_cell({
      c: ref.c - 1,
      r: ref.r
    })
  ] = {
    t: 's',
    v: 'Chip Details'
  }

  // Rows for the cocktails
  for (const [i, cocktail] of cocktails.entries()) {
    let druglistnames = 'No drug'
    if (cocktail.Drugs && cocktail.Drugs.length > 0) {
      druglistnames = cocktail.Drugs.join(', ')
    }
    const cocktailName = cocktail.Name || `Solution ${i + 1}`
    ws[`D${i + 1}`] = {
      t: 's',
      v: `${cocktailName} = ${druglistnames}`
    }
  }
  // Hide some columns

  ws['!cols'] = []
  for (const col of hiddenCols.experimentSheet) {
    const colIndex = utils.decode_col(col)
    ws['!cols'][colIndex] = { hidden: true }
  }
  return { ws, cocktails }
}

const generateDrugPropertiesSheet = (cocktails) => {
  const drugs = [...new Set(cocktails.flatMap(x => x.Drugs))]
  const drugEntries = []
  for (const [idx, drug] of drugs.entries()) {
    console.log('drug', drug)
    drugEntries.push({
      Drug: drug,
      'fu,media': 1,
      'fu,p': 1,
      'R,bp': 1,
      'fu,b': {
        t: 'n',
        v: '',
        f: `C${idx + 2} / D${idx + 2}`
      }
    })
  }
  const ws = utils.json_to_sheet(drugEntries)
  return ws
}

const generateXlsx = (content, descending) => {
  const { ws, cocktails } = generateChipDataSheet(content)
  const msdatasheet = generateMSDataSheet(cocktails, descending)
  const drugpropertiessheet = generateDrugPropertiesSheet(cocktails)
  // clean references
  content.chips.forEach(x => delete x.cocktails)
  content.cocktails.forEach(x => {
    delete x.chips
    delete x.metabolismCondition.chips
    delete x.nsbCondition.chips
  })
  return { chipDataSheet: ws, msDataSheet: msdatasheet, drugPropertiesSheet: drugpropertiessheet }
}

export { generateXlsx }
