import { WorkBook, read } from 'xlsx'
import JSON5 from 'json5'

import { JavelinXlsx } from './xlsx/javelinxlsx.js'

import { importModule } from '../pythonWorker'

class XlsxImport {
  xlsx: any
  importedJson: any

  constructor (xlsx: any, json: any) {
    this.xlsx = xlsx
    this.importedJson = json
  }
}

const parseModelsFromXLSX = async (binaryStringContent: string | ArrayBuffer, name: string, includeRejected: boolean): Promise<XlsxImport> => {
  return workbookAsImportModel(extractXLSXWorkbook(binaryStringContent, name), includeRejected)
}

const workbookAsImportModel = async (workbook: JavelinXlsx, includeRejected: boolean): Promise<XlsxImport> => {
  const jsonxlsx = extractXLSXSheetsAsJSON(workbook)

  const importer = importModule('javelin.xlsximporter')
  const { results: jsonModels, error } = await importer.json_models_from_xlsx_data(jsonxlsx, includeRejected)
  if (jsonModels) {
    return new XlsxImport(workbook, JSON5.parse(jsonModels))
  }
  if (error) {
    throw (`pyodideWorker error: ${error}`)
  }
}

const extractXLSXWorkbook = (data: string | ArrayBuffer, name: string): JavelinXlsx => {
  return workbookAsJavelinXLSX(read(data, { type: 'base64' }), name)
}

const workbookAsJavelinXLSX = (workbook: WorkBook, name): JavelinXlsx => {
  return new JavelinXlsx(workbook, name)
}

const extractXLSXSheetsAsJSON = (workbook: JavelinXlsx): string => {
  const xlsxSheets = workbook.extractSheets()
  return JSON.stringify(
    xlsxSheets,
    (key, value) => {
      if (key === '__xlsx__') {
        return undefined
      }
      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
      ) {
        value.$class = value.constructor.name
      }
      if (Array.isArray(value)) {
        for (const entry of value) {
          if (
            typeof entry === 'object' &&
            !Array.isArray(entry) &&
            entry !== null
          ) {
            entry.$class = entry.constructor.name
          }
        }
      }
      return value
    },
    2
  )
}

export { parseModelsFromXLSX, extractXLSXWorkbook, extractXLSXSheetsAsJSON, workbookAsJavelinXLSX, workbookAsImportModel }
