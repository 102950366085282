import vars from "./variables";
import { createTheme } from "@mui/material/styles";
import TAB_ICON from "../assets/svg/chevron-right.svg";

const {
  primaryFont,
  primaryColor,
  labelColor,
  dialogTitleColor,
  dialogDescColor,
  inputBorderColor,
  white,
  primaryBtnShadow,
  primaryHoverColor,
  inputDisableBorder,
  inputBorderHoverColor,
  tabTextColor,
  dialogBorderColor,
  dialogBgColor,
  placeholderColor,
  secondaryFont,
  primaryTextColor,
  btnDisableColor,
  btnDisableBg,
  btnSecondaryBg,
  blackColor,
  errorBgHover,
  errorBg,
  progressBorderColor,
  inputShadow,
  primaryBtnFocusShadow,
  btnSecondaryBgHover,
  successColor,
  successBgColor,
  btnSecondaryDisableBg
} = vars;

declare module "@mui/material/styles" {
  interface Theme { }
  interface ThemeOptions { }
}

const theme = createTheme({
  typography: {
    fontFamily: primaryFont,

    body2: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: primaryTextColor,
      marginBottom: '0.375rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *, body {
          font-family: ${primaryFont}
        }

        [class*=ag-theme-] {
          --ag-border-color: rgba(224, 224, 224, 1);
          --ag-row-border-color: rgba(224, 224, 224, 1);
          --ag-odd-row-background-color: transparent;
          --ag-header-column-resize-handle-color: rgba(224, 224, 224, 1);
          --ag-row-hover-color: rgba(0, 0, 0, 0.04);
          margin-bottom: 0.375rem
        }

        .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group,.ag-header-cell, .ag-header-group-cell {
          padding: 0 0.625rem;
        }

        .ag-header-cell-resize {
          width: 0.375rem;
        }

        .ag-header {
          background: transparent
        }

        .ag-root-wrapper {
          border-radius: 0.25rem
        }

        .ag-cell, .ag-header-cell-text, .ag-header-group-text {
          color: ${primaryTextColor};
          font-weight: 500;
          line-height: 3.5;
          font-size: 0.875rem;
        }

        .ag-header-group-text {
          white-space: pre;
          line-height: 1.5;
        }

        .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
          border-color: transparent;
        }
      `
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          fontWeight: 500,
          fontSize: '1.5rem',
          lineHeight: '2rem',
          color: dialogTitleColor,
          fontFamily: secondaryFont,

          '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            marginTop: '0.25rem',
            color: dialogDescColor,
          },
        },
      }
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          '&.custom-arrow': {
            '& .MuiTab-root': {
              minHeight: '3.25rem',
              '&:not(:first-of-type)': {
                marginLeft: '2.75rem',
                '&:before': {
                  pointerEvents: 'none',
                  content: '""',
                  background: `url(${TAB_ICON})`,
                  width: '1.25rem',
                  height: '1.25rem',
                  position: 'absolute',
                  left: '-2rem',
                },
              },
            }
          },

          '&.rounded': {
            minHeight: '2.75rem',
            background: dialogBgColor,
            border: `0.0625rem solid ${dialogBorderColor}`,
            padding: '0.25rem',
            borderRadius: '0.5rem',

            '& .MuiTabs-scroller': {
              overflow: 'initial !important',
            },

            '& .MuiTabs-indicator': {
              display: 'none'
            },

            '& .MuiTab-root': {
              width: '50%',
              borderRadius: '0.375rem',
              minHeight: '2.25rem',
              minWidth: 'initial',
              padding: '0 0.75rem',
              color: tabTextColor,

              '&.Mui-selected': {
                background: white,
                boxShadow: '0 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06)',
                color: labelColor,
              },

              '&:not(:first-of-type)': {
                marginLeft: 0,
              },
            }
          },
        },
        indicator: {
          background: primaryColor,
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: inputBorderColor
            },
          },

          '& > .MuiChip-root': {
            margin: '0.25rem 0 0 0.25rem !important'
          },

          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: primaryColor,
              borderWidth: '0.0625rem',
              boxShadow: inputShadow,
            },
          },
        },

        input: {
          height: '2.5rem',
          fontWeight: 400,
          fontSize: '0.875rem',
          padding: '0 0.75rem',
          lineHeight: 1,
          color: dialogTitleColor,

          '&::-webkit-input-placeholder': {
            color: placeholderColor
          },

          '&:-ms-input-placeholder': {
            color: placeholderColor
          },

          '&::placeholder': {
            color: placeholderColor
          },
        },

        notchedOutline: {
          borderColor: inputBorderColor,
          boxShadow: '0 1px 2px rgba(16, 24, 40, 0.05)',
        }
      },
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '0.75rem',
          borderTop: `0.0625rem solid ${inputDisableBorder}`,
        },
      }
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          '& + .MuiFormControlLabel-label': {
            marginLeft: 0
          },
          '&.Mui-checked': {
            color: '#6F469A'
          }
        }
      }
    },

    MuiCardActionArea: {
      styleOverrides: {
        root: {
          borderRadius: '0.75rem',
          padding: '0.25rem',

          '& a': {
            textDecoration: 'none'
          },

          '&:hover': {
            background: dialogBorderColor,

            '& .cardIcon': {
              borderColor: inputDisableBorder,
              boxShadow: '0 0.75rem 1ren -0.25rem rgba(16, 24, 40, 0.08), 0 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03)',
            },

            '& .action-text': {
              opacity: 1,
            },
          }
        },

        focusHighlight: {
          opacity: 0,
        }
      }
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0.5rem',

          '& .action-text': {
            display: 'flex',
            alignItems: 'center',
            opacity: 0,
            fontWeight: 600,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            color: primaryColor,
            transition: 'all ease-in-out .1s',

            '& svg': {
              marginRight: '0.5rem',
            },
          },

          '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
            marginBottom: '0.75rem',
            color: tabTextColor,
          },

          '& .MuiTypography-h5': {
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: blackColor
          },
        }
      }
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: progressBorderColor
          }
        }
      }
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '0.75rem',
        },

        action: {
          margin: 0,

          '& .MuiCheckbox-root': {
            padding: 0,
          },

          '& svg': {
            display: 'block',
          },
        },

        title: {
          fontWeight: 600,
          fontSize: '0.75rem',
          lineHeight: '1.125rem',
          color: labelColor,
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          padding: '0',
          boxShadow: 'none',
          borderRadius: '1rem',

          '&.MuiPaper-outlined': {
            border: `0.0625rem solid ${inputDisableBorder}`,
            borderRadius: '0.5rem',

            '&.active': {
              borderColor: progressBorderColor,
              boxShadow: `0 0 0 0.0625rem ${progressBorderColor}`,

              '& .MuiCardContent-root': {
                borderColor: progressBorderColor,
                boxShadow: `0 0 0 0.0625rem ${progressBorderColor}`,
              }
            },

            '& .MuiCardActionArea-focusHighlight': {
              display: 'none'
            },

            '& .MuiCardActionArea-root': {
              padding: 0,
              borderRadius: '0.375rem',

              '&:hover': {
                background: white
              }
            },

            '& .MuiCardContent-root': {
              padding: '0.75rem',
              borderTop: `0.0625rem solid ${inputDisableBorder}`,

              '& p': {
                margin: 0,
                fontWeight: 400,
                fontSize: '0.75rem',
                lineHeight: '1.125rem',
                color: primaryTextColor,
              },
            },
          },

          '& .cardIcon': {
            background: dialogBgColor,
            border: '0.0625rem solid transparent',
            height: '12.0625rem',
            boxShadow: '0 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06)',
            borderRadius: '0.75rem',
            overflow: 'hidden',
          },
        },
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          fontWeight: 600,
          textTransform: 'none',
          borderRadius: '0.5rem',
          padding: '0 1.125rem',
          transition: 'all ease-in-out .3s',
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          height: '2.5rem',

          '& .left-icon': {
            marginRight: '0.5rem',
          },

          '& .right-icon': {
            marginLeft: '0.5rem',
          },
        },

        textSecondary: {
          color: primaryTextColor
        },

        text: {
          fontWeight: 600,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          padding: 0,
          minWidth: '0.0625rem',
          height: 'auto',
          color: primaryColor,

          '&:hover': {
            background: 'transparent'
          }
        },

        containedPrimary: {
          background: progressBorderColor,
          color: white,
          boxShadow: primaryBtnShadow,


          '&.Mui-disabled': {
            background: btnDisableBg,
            color: white,
          },

          '&:hover': {
            boxShadow: primaryBtnShadow,
            background: primaryColor,
          },

          '&:focus': {
            boxShadow: primaryBtnFocusShadow,
            background: progressBorderColor,
          },
        },

        containedSecondary: {
          background: btnSecondaryBg,
          color: primaryColor,
          boxShadow: 'none',


          '&.Mui-disabled': {
            background: btnSecondaryDisableBg,
            color: btnDisableColor,

            '& .download-icon g path': {
              stroke: btnDisableColor
            }
          },

          '&:hover': {
            background: btnSecondaryBgHover,
            color: primaryColor,
            boxShadow: 'none'
          },

          '&:focus': {
            background: btnSecondaryBg,
            color: primaryColor,
            boxShadow: primaryBtnFocusShadow
          },
        },

        containedError: {
          background: errorBg,
          color: white,

          '&:hover': {
            background: errorBgHover,
            boxShadow: 'none'
          },

          '&:focus': {
            background: errorBg,
            boxShadow: '0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #FEE4E2'
          },
        },

        outlinedPrimary: {
          borderColor: inputBorderColor,
          backgroundColor: white,
          color: labelColor,

          '&.disabled': {
            borderColor: inputDisableBorder,
            color: inputBorderColor,
            pointerEvents: 'none',

            '& svg path': {
              stroke: inputBorderColor
            }
          },

          '&:hover': {
            borderColor: inputBorderColor,
            background: inputBorderHoverColor,
            boxShadow: '0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05)',
          },

          '&:focus': {
            borderColor: inputBorderColor,
            background: white,
            boxShadow: '0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #F2F4F7',
          },
        },
      },
    },

    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          fontWeight: 600,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          color: tabTextColor,
          minWidth: '0.0625rem',
          textTransform: 'none',
          flexDirection: 'row',
          position: 'relative',
          overflow: 'visible',

          '&.optional': {
            opacity: '0.5'
          },

          '&.Mui-selected': {
            color: primaryColor,
          },

          '&:not(:first-of-type)': {
            marginLeft: '1rem',
          },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          background: dialogBgColor,
          padding: '1.5rem',
          gap: '0 0.75rem',

          '& .MuiButton-root': {
            flexGrow: 1,
            width: 'calc(50% - 0.375rem)'
          },

          '& > :not(:first-of-type)': {
            margin: 0,
          }
        }
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '1.5rem',
          height: 'calc(100vh - 18.9375rem)',

          '&.disableGutter': {
            padding: 0,
          },

          '&.height-auto': {
            height: 'auto'
          },

          '&.height-full > div': {
            height: '100%'
          },

          '& h3': {
            fontWeight: 600,
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
            color: dialogTitleColor,
            marginBottom: '1.5rem',
          },
        }
      },
    },

    MuiDialog: {
      styleOverrides: {
        paperWidthXs: {
          maxWidth: '17.125rem',
        },
        paper: {
          width: '100%',
          maxWidth: '55rem',
          border: `0.0625rem solid ${dialogBorderColor}`,
          boxShadow: '0 6.25rem 12.5rem rgba(52, 64, 84, 0.18)',
          borderRadius: '1rem',

          '& .bg-gray': {
            background: dialogBgColor
          },

          '& .border-bottom': {
            borderBottom: `0.0625rem solid ${inputDisableBorder}`,
          },
        }
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          minHeight: 'inherit !important',
          padding: '0.8125rem 1.5rem !important',
        },

        displayedRows: {
          margin: 0,
        },
      }
    },

    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: primaryTextColor,

            '& .MuiTableSortLabel-icon': { color: primaryTextColor, },
          },
        }
      }
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '& th': {
            color: dialogTitleColor,
            fontWeight: 500,
            whiteSpace: 'nowrap'
          }
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: dialogDescColor,
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          borderBottomColor: inputDisableBorder,
        },

        head: {
          fontWeight: 500,
          fontSize: '0.75rem',
          lineHeight: '1.125rem',
        },

        body: {
        },
      }
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: white,
          border: `0.0625rem solid ${inputDisableBorder}`,
          color: primaryTextColor,
        },

        arrow: {
          color: inputDisableBorder,
        }
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          background: dialogBorderColor,
          borderRadius: '0.3125rem',
          padding: '0.25rem 0.625rem',
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          color: labelColor,

          '&.rounded': {
            borderRadius: '3rem',
          },
        },

        colorSuccess: {
          background: successBgColor,
          color: successColor,
        },

        deleteIcon: {
          margin: '0 0 0 0.1875rem'
        },

        sizeSmall: {
          height: '1.5rem',
        },

        label: {
          padding: 0
        }
      }
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: '2.5rem',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0.25rem',
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
          boxSizing: 'border-box',

          '& .MuiChip-root': {
            margin: '0 !important'
          },

          '& em': {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '0.875rem',
            paddingLeft: '0.5rem',
            lineHeight: 1,
            color: placeholderColor,
          },
        }
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          rowGap: '0 !important',
          '& input': {
            padding: '0 0.75rem !important',
          }
        },

        inputMultiline: {
          padding: '0.75rem !important',
        }
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          padding: '0',
        }
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '0',
        }
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          border: 'none',
          borderTop: `0.0625rem solid ${dialogBorderColor}`
        }
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '0.625rem 0.75rem',
        }
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },

        primary: {
          fontWeight: 400,
          fontSize: '0.875rem',
          lineHeight: '1.25rem',
          color: tabTextColor
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: secondaryFont,
          fontWeight: 500,
          color: dialogTitleColor
        }
      }
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'inherit',
          marginRight: '0.75rem',
          '&:last-of-type': {
            marginLeft: '0.75rem',
            marginRight: '0',
          }
        }
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          width: '2.25rem',
          height: '1.25rem',
        },

        thumb: {
          width: '1rem',
          height: '1rem',
          background: white,
          boxShadow: '0 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.06)'
        },

        switchBase: {
          padding: 0,
          top: '0.125rem',
          left: '0.125rem',

          '&.Mui-checked': {
            background: white,
            color: white,
            transform: 'translateX(1rem)',

            '&+.MuiSwitch-track': {
              background: progressBorderColor,
              opacity: 1,
            },
          }
        },

        track: {
          background: dialogBorderColor,
          opacity: 1,
          borderRadius: '0.75rem',
        }
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },

        label: {
          fontWeight: 500,
          fontSize: '0.875rem',
          marginLeft: '0.5rem',
          lineHeight: '1.25rem',
          color: labelColor,
        }
      }
    },
  }
});

export default theme;
