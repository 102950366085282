const chartPlotStyle = {
  color1: '#6F469A',
  color2: '#EF6820',
  data: {
  },
  yaxis: {
    tickfont: {
      size: 12,
      color: '#344054'
    },
    ticklen: 16,
    tickcolor: 'white',
    automargin: true,
    zeroline: false,
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    linecolor: '#F2F4F7'
  },
  xaxis: {
    tickfont: {
      size: 12,
      color: '#344054'
    },
    automargin: true,
    autorange: true,
    linecolor: '#EAECF0',
    ticklen: 16,
    tickcolor: 'white',
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    griddash: 'dot',
    zeroline: false
  }
}

const barPlotStyle = {
  color1: '#815CAB',
  color2: '#475467',
  data: {
    width: 0.20
  },
  xaxis: {
    tickfont: {
      size: 12,
      color: '#344054'
    },
    linecolor: '#EAECF0',
    ticklen: 16,
    tickcolor: 'white',
    automargin: true,
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    griddash: 'dot',
    zeroline: false
  },
  yaxis: {
    autorange: true,
    tickfont: {
      size: 11,
      color: '#344054'
    },
    ticklen: 12,
    tickcolor: 'white',
    tickformat: ',.2~r',
    automargin: true,
    zeroline: false,
    titlefont: {
      size: 12,
      color: '#475467',
      weight: 500
    },
    linecolor: '#F2F4F7',
    showticklabels: true
  }
}

const SYMBOL_PALETTE = [
  'circle',
  'square',
  'diamond',
  'cross',
  'triangle-up',
  'hexagon',
  'hexagram',
  'star-triangle-up',
  'star-triangle-down',
  'star-square',
  'star-diamond',
  'diamond-tall',
  'diamond-wide',
  'hourglass',
  'bowtie',
  'square-cross',
  'square-x',
  'circle-x'
]

export { chartPlotStyle, barPlotStyle, SYMBOL_PALETTE }
