import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Tooltip from '@mui/material/Tooltip'
import ExperimentDetail from './Steps/ExperimentDetail'
import ExperimentSetup from './Steps/ExperimentSetup'
import ChipDetails from './Steps/ChipDetails'
import LoadingDialog from './Steps/LoadingDialog'
import CompletionDialog from './Steps/CompletionDialog'
import TabWrapper from './TabWrapper'
import vars from '../../styles/variables';
import {
  CHIP_DEFAULT_FLOW_RATE, CHIP_DEFAULT_HEP_DONOR,
  CHIP_DEFAULT_HEP_NUM, CHIP_DEFAULT_LY_VOL, CHIP_DEFAULT_MEDIA_TYPE,
  CHIP_DEFAULT_TOTAL_VOL,
  CHIP_INPUTS,
  COCKTAIL_INPUTS,
  EXPERIMENT_INPUTS, INTRACELLULAR_CONDITION, LOCAL_STORAGE_EXPERIMENT,
  LOCATION_INPUTS, METABOLISM_CONDITION, NSB_CONDITION, WORKING_SOLUTION
} from '../../shared/constant'
import { extractXLSXWorkbook } from "../../utils/javelinimporter";
import ImportDialog from "../FileUploader/ImportDialog";
import localStorageService from '../../services/localStorageService';


const tabs = ["Experiment Setup", "Chip Details", "Drug Solution", "Chip with Tissue", "Chip only"]
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const chipDefaults = {
  [CHIP_INPUTS.id]: '',
  [CHIP_INPUTS.flowRate]: CHIP_DEFAULT_FLOW_RATE,
  [CHIP_INPUTS.totalVol]: CHIP_DEFAULT_TOTAL_VOL,
  [CHIP_INPUTS.hepNum]: CHIP_DEFAULT_HEP_NUM,
  [CHIP_INPUTS.hepDonor]: CHIP_DEFAULT_HEP_DONOR,
  [CHIP_INPUTS.lyVol]: CHIP_DEFAULT_LY_VOL,
  [CHIP_INPUTS.lungVol]: '',
  [CHIP_INPUTS.rna]: '',
  [CHIP_INPUTS.dna]: '',
  [CHIP_INPUTS.mediaType]: CHIP_DEFAULT_MEDIA_TYPE,
}

const conditionDefaults = {
  [COCKTAIL_INPUTS.sampleVolume]: '50 ul',
  [COCKTAIL_INPUTS.timepoints]: [],
  [COCKTAIL_INPUTS.locations]: [],
  [COCKTAIL_INPUTS.technicalReplicates]: '3'
}

const intracellularDefaults = {
  selected: false,
  [COCKTAIL_INPUTS.sampleVolume]: '100 ul',
  [COCKTAIL_INPUTS.locations]: [LOCATION_INPUTS.locationCellCamber],
  [COCKTAIL_INPUTS.technicalReplicates]: '1',
}


const metabolismDefaults = {
  [COCKTAIL_INPUTS.timepoints]: [1, 4, 24, 48, 72],
  [COCKTAIL_INPUTS.sampleVolume]: '50 ul',
  [COCKTAIL_INPUTS.locations]: [LOCATION_INPUTS.locationReoxygenationChamber],
  [COCKTAIL_INPUTS.linkedChips]: [],
  [COCKTAIL_INPUTS.technicalReplicates]: '1',
  [COCKTAIL_INPUTS.intracellular]: { ...intracellularDefaults }
}

const nsbDefaults = {
  [COCKTAIL_INPUTS.timepoints]: [1, 4, 24, 48, 72],
  [COCKTAIL_INPUTS.sampleVolume]: '50 ul',
  [COCKTAIL_INPUTS.locations]: [LOCATION_INPUTS.locationReoxygenationChamber],
  [COCKTAIL_INPUTS.linkedChips]: [],
  [COCKTAIL_INPUTS.technicalReplicates]: '1'
}

const cocktailDefaults = {
  [COCKTAIL_INPUTS.drugs]: [],
  [COCKTAIL_INPUTS.workingSolution]: {
    ...conditionDefaults,
    [COCKTAIL_INPUTS.locations]: [LOCATION_INPUTS.locationNA],
    [COCKTAIL_INPUTS.timepoints]: [0, ...conditionDefaults[COCKTAIL_INPUTS.timepoints]]
  },
  [COCKTAIL_INPUTS.metabolismCondition]: { ...metabolismDefaults },
  [COCKTAIL_INPUTS.nsbCondition]: { ...nsbDefaults }
}

const experimentDefaults = {
  [EXPERIMENT_INPUTS.id]: '',
  [EXPERIMENT_INPUTS.chips]: [{ ...chipDefaults },],
  [EXPERIMENT_INPUTS.cocktails]: [{ ...cocktailDefaults },]
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

const cloneExperiment = (experiment) => {
  return JSON.parse(JSON.stringify(experiment))
}

export default function CreateExperimentDialog(props) {
  const { open, handleClose } = props
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [excelGeneration, setExcelGeneration] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [tabValueCocktail, setTabValueCocktail] = useState(0)
  const initialExperiment = localStorageService.get(LOCAL_STORAGE_EXPERIMENT) || cloneExperiment(experimentDefaults)
  const [experiment, setExperiment] = useState(initialExperiment)
  const [elementFocus, setElementFocus] = useState(false)
  const [openUploadDialog, setOpenUploadDialog] = useState(false)


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleTabChangeCocktail = (event: React.SyntheticEvent, newValue: number) => {
    setTabValueCocktail(newValue);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(newValue === 5) {
      setValue(0);
      setExcelGeneration(true);
    } else {
      setValue(newValue);
    }
  }

  const handleCloseDialog = () => {
    setValue(0)
    setExcelGeneration(false)
    handleClose()
  }

  const handleImportFromFile = (uploads: any[]) => {
    if(uploads.length > 0) {
      const file = uploads[0]
      try {
        const template = extractXLSXWorkbook(file.content, file.meta.name).extractSheets()
        populateFromTemplate(template)
        setTabValue(0)
        setTabValueCocktail(0)
      } catch (e) {
        console.error(e)
        setOpenUploadDialog(false)
        return
      }
    }
    setOpenUploadDialog(false)
  }



  const populateFromTemplate = (template: any) => {
    if(!template) {
      return
    }

    const solutionsData = template.msdata.reduce((acc, item) => {
      const solutionAttribute = 'cocktail' in item ? 'cocktail' : 'solution'
      if (!acc[item[solutionAttribute].value]) {
        acc[item[solutionAttribute].value] = [];
      }
      acc[item[solutionAttribute].value].push(item);
      return acc;
    }, {});

    const solutions = Object.keys(solutionsData)

    setExperiment({
      [EXPERIMENT_INPUTS.id]: template.expinfo.infos.experiment_id,
      [EXPERIMENT_INPUTS.chips]: template.expinfo.chips.map(templateChip => templateToChip(templateChip)),
      [EXPERIMENT_INPUTS.cocktails]: solutions.length > 0 ? solutions.map(key => templateToCocktail(solutionsData[key])) :
        [{ ...cocktailDefaults }]
    })
  }

  const templateToChip = (chip: any) => {
    return {
      [CHIP_INPUTS.id]: chip.chip_id.value || '',
      [CHIP_INPUTS.flowRate]: chip.flow_rate.value ? `${chip.flow_rate.value} ${chip.flow_rate.unit}`: CHIP_DEFAULT_FLOW_RATE,
      [CHIP_INPUTS.totalVol]: chip.total_volume.value ? `${chip.total_volume.value} ${chip.total_volume.unit}` : CHIP_DEFAULT_FLOW_RATE,
      [CHIP_INPUTS.hepNum]: chip.hepatocyte_donor.value ? `${chip.hepatocyte_number.value}` : CHIP_DEFAULT_HEP_NUM,
      [CHIP_INPUTS.hepDonor]: chip.hepatocyte_donor.value ? `${chip.hepatocyte_donor.value}` : CHIP_DEFAULT_HEP_DONOR,
      [CHIP_INPUTS.lyVol]: chip.lysis_volume.value ? `${chip.lysis_volume.value} ${chip.lysis_volume.unit}` : CHIP_DEFAULT_LY_VOL,
      [CHIP_INPUTS.lungVol]: chip.final_lung_volume.value ? `${chip.final_lung_volume.value} ${chip.final_lung_volume.unit}` : '',
      [CHIP_INPUTS.rna]: chip.rna.value ? `${chip?.rna.value} ${chip.rna.unit}` : '',
      [CHIP_INPUTS.dna]: chip.dna.value ? `${chip.dna.value} ${chip.dna.unit}` : '',
      [CHIP_INPUTS.mediaType]: chip.media_type ? `${chip.media_type.value} ${chip.media_type.unit}` : CHIP_DEFAULT_MEDIA_TYPE,
      [CHIP_INPUTS.comment]: chip.comments.value || ''
    }
  }
  const templateToCocktail = (solution) => {
    const filterByCondition = (conditionValue) => solution.filter(e => e.condition.value.toLowerCase() === conditionValue.toLowerCase());

    const metabolismConditionData = filterByCondition(METABOLISM_CONDITION)
    const nsbConditionData = filterByCondition(NSB_CONDITION)

    const workingCondition = extractDetails(filterByCondition(WORKING_SOLUTION));
    const metabolismCondition = extractDetails(metabolismConditionData);
    const intracellularConditions = extractDetails(filterByCondition(INTRACELLULAR_CONDITION));
    const nsbConditions = extractDetails(nsbConditionData);

    return {
      [COCKTAIL_INPUTS.drugs]: [...new Set(solution.map(e => e.drug.value))],
      [COCKTAIL_INPUTS.workingSolution]: workingCondition,
      [COCKTAIL_INPUTS.metabolismCondition]: {
        ...metabolismCondition,
        ...extractLinkedChips(metabolismConditionData),
        [COCKTAIL_INPUTS.intracellular]: intracellularConditions || { ...intracellularDefaults }
      },
      [COCKTAIL_INPUTS.nsbCondition]: nsbConditions ?
        { ...nsbConditions,
          ...extractLinkedChips(nsbConditionData) } : { ...nsbDefaults }
    };
  }

  const extractDetails = (conditions) => {
    if (conditions.length === 0) {return null;}

    return {
      [COCKTAIL_INPUTS.timepoints]: [...new Set(conditions.map(e => e.sample_time.value))],
      [COCKTAIL_INPUTS.sampleVolume]: `${conditions[0].sample_volume.value} ${conditions[0].sample_volume._meta.raw_unit}`,
      [COCKTAIL_INPUTS.technicalReplicates]: Math.max(...conditions.map(e => e.technical_replicate.value)),
      [COCKTAIL_INPUTS.locations]: [...new Set(conditions.map(e => e.sample_location.value))],
    };
  }

  const extractLinkedChips = (conditions) => {
    if (conditions.length === 0) {return null;}

    return {
      [COCKTAIL_INPUTS.linkedChips]: [...new Set(conditions.map(e => e.chip_id.value))]
    };
  }

  const addChip = () => {
    // add new chip with default values as previous chip
    // @ts-ignore
    const templateChip = { ...experiment[EXPERIMENT_INPUTS.chips][experiment[EXPERIMENT_INPUTS.chips].length - 1],
      [CHIP_INPUTS.id]: ''  }

    // @ts-ignore
    experiment[EXPERIMENT_INPUTS.chips].push(templateChip)
    setExperiment({
      ...experiment,
    })
    setTabValue(experiment[EXPERIMENT_INPUTS.chips].length - 1)
  }

  const removeChip = (indexToRemove) => {
    // @ts-ignore
    const newChips = experiment[EXPERIMENT_INPUTS.chips].filter((element, index) => index !== indexToRemove)
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.chips]: [...newChips]
    })
    if(experiment[EXPERIMENT_INPUTS.chips].length-1 === tabValue) {
      setTabValue(tabValue-1)
    }
  }

  const chipValueChange = (value, key, index) => {
    const updatedChips = [...experiment[EXPERIMENT_INPUTS.chips]]
    // @ts-ignore
    updatedChips[index] = { ...updatedChips[index], [key]: value }
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.chips]: [...updatedChips]
    })
  }

  const addCocktail = () => {
    // add new cocktail with default values
    // @ts-ignore
    experiment.cocktails.push({ ...cocktailDefaults })
    setExperiment({
      ...experiment,
    })
  }

  const removeCocktail = (indexToRemove) => {
    // @ts-ignore
    const newCocktails = experiment[EXPERIMENT_INPUTS.cocktails].filter((element, index) => index !== indexToRemove)
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.cocktails]: [...newCocktails]
    })
    if(experiment[EXPERIMENT_INPUTS.cocktails].length-1 === tabValueCocktail) {
      setTabValueCocktail(tabValueCocktail-1)
    }
  }

  const cocktailValueChange = (cocktail, index = null) => {
    const updatedCocktails = [...experiment[EXPERIMENT_INPUTS.cocktails]]
    updatedCocktails[index || tabValueCocktail] = { ...cocktail }
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.cocktails]: [...updatedCocktails]
    })
  }

  const experimentIdValueChange = (experimentID) => {
    // @ts-ignore
    setExperiment({
      ...experiment,
      [EXPERIMENT_INPUTS.id]: experimentID
    })
  }

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const resetExperiment = () => {
    const defaultExperiment = cloneExperiment(experimentDefaults)
    setExperiment(defaultExperiment)
    setTabValue(0)
    setTabValueCocktail(0)
    // The update is done by the "useEffect" below
    // localStorageService.set(LOCAL_STORAGE_EXPERIMENT, defaultExperiment);
  };

  useEffect(() => {
    localStorageService.set(LOCAL_STORAGE_EXPERIMENT, experiment);
  }, [experiment])


  const classes = {
    shadow: {
      boxShadow: '0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03)'
    }
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      {loading ? (
        <LoadingDialog />
      ) : excelGeneration ? (
        <CompletionDialog handleClose={handleCloseDialog} experiment={experiment} />
      ) : (
        <>
          <Box
            className='bg-gray'
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
            pb={1.5}
          >
            <DialogTitle>
              Template generator
              <Typography>
                To generate a data file template, please fill out the informations below.
              </Typography>
            </DialogTitle>
            <IconButton
              sx={{ padding: 0 }}
              aria-label="close"
              onClick={handleCloseDialog}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={classes.shadow}
            zIndex={1}
            className='bg-gray border-bottom'
            px={3}
          >
            <Tabs className='custom-arrow' value={value} onChange={handleChange}>
              {tabs.map((tab, index) => {
                const tabLabel = index + 1 + ". " + tab
                if (index == tabs.length - 1) { // set optional on last tab (chips only)
                  return (
                    <Tooltip key={index} title="Optional" placement="right" arrow>
                      <Tab  className="optional" label={tabLabel} {...a11yProps(index)} />
                    </Tooltip>
                  )
                }
                return <Tab key={index} label={tabLabel} {...a11yProps(index)} />
              })}

            </Tabs>
          </Box>

          <DialogContent className="disableGutter height-full">

            <TabPanel value={value} index={0}>
              <TabWrapper handleChange={handleChange} value={value} heading={value + 1 + ". " + tabs[0]}
                setElementFocus={setElementFocus} onImportFromFileClick={() => setOpenUploadDialog(true)}
                onResetDefaults={resetExperiment}
              >
                <ExperimentSetup experimentId={experiment[EXPERIMENT_INPUTS.id]} setExperimentId={experimentIdValueChange} />
                <ImportDialog open={openUploadDialog} handleClose={handleImportFromFile} />
              </TabWrapper>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <TabWrapper
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                onAdd={() => addChip()}
                onRemove={removeChip}
                arr={experiment[EXPERIMENT_INPUTS.chips]}
                handleChange={handleChange}
                value={value}
                heading={value + 1 + ". " + tabs[1]}
                setElementFocus={setElementFocus}
              >
                <ChipDetails tabValue={tabValue} chip={experiment[EXPERIMENT_INPUTS.chips][tabValue]} onChange={chipValueChange} elementFocus={elementFocus} />
              </TabWrapper>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <TabWrapper
                tabValue={tabValueCocktail}
                handleTabChange={handleTabChangeCocktail}
                onAdd={addCocktail}
                onRemove={removeCocktail}
                arr={experiment[EXPERIMENT_INPUTS.cocktails]}
                handleChange={handleChange}
                value={value}
                heading={value + 1 + ". " + tabs[2]}
                cocktailValueChange={cocktailValueChange}
                cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
                setElementFocus={setElementFocus}
              >
                <ExperimentDetail
                  showDrugs={true}
                  cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
                  cocktailValueChange={cocktailValueChange}
                  detailKey={COCKTAIL_INPUTS.workingSolution}
                  linkedChips={false}
                  chips={experiment[EXPERIMENT_INPUTS.chips]}
                  heading={value + 1 + ". " + tabs[2]}
                  tabValue={value}
                />
              </TabWrapper>
            </TabPanel>

            <TabPanel value={value} index={3}>
              <TabWrapper
                tabValue={tabValueCocktail}
                handleTabChange={handleTabChangeCocktail}
                onAdd={addCocktail}
                onRemove={removeCocktail}
                arr={experiment[EXPERIMENT_INPUTS.cocktails]}
                handleChange={handleChange}
                value={value}
                heading={value + 1 + ". " + tabs[3]}
                setElementFocus={setElementFocus}
              >
                <ExperimentDetail
                  showDrugs={false}
                  cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
                  cocktailValueChange={cocktailValueChange}
                  detailKey={COCKTAIL_INPUTS.metabolismCondition}
                  linkedChips={true}
                  chips={experiment[EXPERIMENT_INPUTS.chips]}
                  heading={value + 1 + ". " + tabs[3]}
                  tabValue={value}
                />
              </TabWrapper>
            </TabPanel>

            <TabPanel value={value} index={4}>
              <TabWrapper
                tabValue={tabValueCocktail}
                handleTabChange={handleTabChangeCocktail}
                onAdd={addCocktail}
                onRemove={removeCocktail}
                arr={experiment[EXPERIMENT_INPUTS.cocktails]}
                handleChange={handleChange}
                value={value}
                heading={`${value + 1}. ${tabs[4]} (optional)`}
                setElementFocus={setElementFocus}
              >
                <ExperimentDetail
                  showDrugs={false}
                  cocktail={experiment[EXPERIMENT_INPUTS.cocktails][tabValueCocktail]}
                  cocktailValueChange={cocktailValueChange}
                  detailKey={COCKTAIL_INPUTS.nsbCondition}
                  linkedChips={true}
                  chips={experiment[EXPERIMENT_INPUTS.chips]}
                  heading={value + 1 + ". " + tabs[4]}
                  tabValue={value}
                />
                <Typography sx={vars.label} variant='body2'>Optional: use chip only samples when assessing nonspecific binding</Typography>
              </TabWrapper>
            </TabPanel>
          </DialogContent>
        </>
      )}

    </Dialog>
  );
}
