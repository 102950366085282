import * as React from 'react';
import Input from './Input';

export default function ExperimentSetup({ experimentId, setExperimentId }) {

  return (
    <>
      <Input
        value={experimentId}
        label="Experiment ID"
        onChange={setExperimentId}
      />
    </>
  )
}