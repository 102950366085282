import React from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box } from '@mui/material';


export default function SubHeaderDrugTable ({ headers, rows }) {
  return (
    <Box className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
      <AgGridReact
        columnDefs={headers}
        rowHeight={52}
        rowData={rows}
        suppressAutoSize
        defaultColDef={{ resizable: true, sortable: true, initialWidth: 70 }}
        multiSortKey={'ctrl'}
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
      />
    </Box>
  )
}
