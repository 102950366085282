import { Box, Grid } from '@mui/material';
import React from 'react'
import Input from './Input';
import { CHIP_INPUTS, chipKeys } from '../../../shared/constant';
import ChipInput from './ChipInput';

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

export default function ChipDetails({ chip, onChange, tabValue, elementFocus }: any) {
  return (
    <>
      <TabPanel value={tabValue} index={tabValue} key={tabValue}>
        <Input mb={2} label={CHIP_INPUTS.id} value={chip[CHIP_INPUTS.id]} onChange={(val) => onChange(val, CHIP_INPUTS.id, tabValue)} focus={elementFocus} />

        <Box mb={2}>
          <Grid container spacing={3}>
            {
              chipKeys.map((detailKey, detailIndex) => <Grid xs={6} item key={`chipdetails${detailIndex}${tabValue}`}><ChipInput val={chip[detailKey]} index={tabValue} onChange={onChange} detailKey={detailKey} /></Grid>)
            }
          </Grid>
        </Box>

        <Input mb={2} multiline={true} rows={2} label={CHIP_INPUTS.comment} value={chip[CHIP_INPUTS.comment]} onChange={(val) => onChange(val, CHIP_INPUTS.comment, tabValue)} />
      </TabPanel>
    </>
  )
}
